import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { MDBCard, MDBCardTitle, MDBCardText } from "mdbreact";

class Category extends React.Component {

    render = () => {
        const { appInstallationId, deckId } = this.props.match.params;
        return (
            <div className="col-6 p-1">
                <Link to={`/installed/${appInstallationId}/deck/${deckId}/category/${this.props.categoryId}/`} style={{color: '#ffffff'}}>
                    <MDBCard className="card-body w-100 p-2" style={{backgroundColor: '#'+this.props.category.Color, minHeight: '175px'}}>
                        <MDBCardTitle>{this.props.category.Name}</MDBCardTitle>
                        <MDBCardText>
                        </MDBCardText>
                    </MDBCard>
                </Link>
            </div>
        )
    }
}

export default withRouter(Category);
