import React from 'react';
import { Link } from "react-router-dom";
import { MDBCard, MDBCardTitle, MDBCardText } from "mdbreact";

class AfterDecks extends React.Component {

    render = () => {
        return (
            <div className="col-6 p-1">
                <a href="http://ins.li/kiwahavainto" target="_blank" style={{color: '#ffffff'}}>
                    <MDBCard className="card-body w-100 p-2" style={{backgroundColor: '#404040', minHeight: '175px'}}>
                        <MDBCardTitle>Turvallisuus-<br/>havainto</MDBCardTitle>
                    </MDBCard>
                </a>
            </div>
        )
    }
}

export default AfterDecks;
