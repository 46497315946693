import React from 'react';
import logoTapaturva from '../img/logo_tapaturva.png';

class BottomBar extends React.Component {

    render = () => {
        return (
            <div>
                <div className="footer p-1 elegant-color-dark text-light" style={{position:'fixed', bottom: '0', left: '0', width: '100%', fontSize: '10pt'}}>
                    <span className="mr-1">&copy;&nbsp;Copyright&nbsp;</span>
                    <a href="https://tapaturva.fi" target="_blank">
                        <img src={logoTapaturva} alt='Tapaturva' style={{height: '12px', display:'inlineBlock', marginTop:'-4px'}} />
                    </a>
                </div>
            </div>
        );
    }

}

// function mapStateToProps(state) {
//     return {
//         status: state.status
//     };
// }
// export default connect(
//     mapStateToProps,
//     {
//         "foo": "bar"
//     }
// )(BottomBar);

export default BottomBar;