import React from 'react';
import { connect } from 'react-redux';
import Deck from './Deck';
import KiwaAfterDecks from './custom/kiwa/AfterDecks';

class Decks extends React.Component {

    state = {};

    componentDidMount = () => {
        if (window.RENDER_AFTER_DECKS) {
            this.setState({customComponent: window.RENDER_AFTER_DECKS});
        }
    }

    renderAfterDecks = () => {
        if (this.state.customComponent) {
            try {
                if (this.state.customComponent === 'KiwaAfterDecks') {
                    return <KiwaAfterDecks />
                }
            }
            catch(e) {
                return (<p>Error: {e}</p>);
            }
        }
        return null;
    }

    render = () => {
        return (
            <div className="container mt-1">
                <h4 className="mb-0" style={{textAlign: 'center'}}>Pakat</h4>
                <div className="row">
                    {
                        this.props.decks.length > 0 ?
                            this.props.decks.map( (val, i) => {
                                return <Deck key={i} deck={val} />
                            })
                            :
                            null
                    }
                    { this.renderAfterDecks() }
                </div>
                <div className="p-2">&nbsp;</div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        decks: state.decks
    };
}
export default connect(
    mapStateToProps,
    {
        foo: "bar"
    }
)(Decks);
