import React from 'react';
import { connect } from 'react-redux';
// import { Link } from "react-router-dom";
import { MDBBtn } from "mdbreact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { openSearchModal } from '../actions'

class ButtonSearch extends React.Component {

    state = {
        isOpen: false
    }

    toggleModal = () => {
        // this.setState({
        //     modal: !this.state.isOpen
        // });

    }

    render = () => {
        const steps = parseInt(this.props.stepsBack) || 0;
        let linkto = '';
        for(let i = 0; i < steps; i++) {
            linkto += '../';
        }
        return (
            <div style={{color: '#ffffff', position: 'absolute', right: 0, top: '57px'}}>

            {/* <Link to={linkto+"search/"} style={{color: '#ffffff', position: 'absolute', right: 0, top: '57px'}}> */}
            <MDBBtn className="btn-sm m-0 p-2" color="dark" onClick={ this.props.openSearchModal }>
                <FontAwesomeIcon className="grey-text" icon={faSearch} size="lg"/>
                {/* <span className="grey-text ml-2">Haku</span> */}
            </MDBBtn>
            {/* </Link> */}

            </div>
        )
    }
}

// export default ButtonSearch;
function mapStateToProps(state) {
    return {
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        openSearchModal
    }
)(ButtonSearch);
