import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from "mdbreact";
import { loadSettings, saveSettings, activateLicense, fetchDecks, fetchLicenses } from '../actions'
import License from "./License";
import * as serviceWorker from '../serviceWorker';
// import api from '../api';

class Settings extends React.Component {

    state = {};

    saveSettings = () => {
        this.props.saveSettings(this.props.settings);
    }

    activateLicense = () => {
        this.props.activateLicense(this.props.settings.AppInstallationId, this.props.settings.serialNumber);
    }

    fetchDecks = () => {
        this.props.fetchDecks(true);
    }

    update = async () => {
        serviceWorker.unregister();
        window.location.reload(true);
    }

    render = () => {

        const decksFetched = new Date(this.props.settings.decksFetched);

        return (
            <div>
                <h4>Lisenssit
                    <i onClick={this.props.toggleModal} style={{ position: 'absolute', right: '1rem', top: '1rem' }} className="fas fa-times-circle text-danger"></i>
                </h4>
                <span>Syötä koodi</span><br />
                <input type="text" className="form-control mb-1" id="input_serialNumber"
                    defaultValue={this.props.settings.serialNumber || ''}
                    onChange={(e) => { this.props.settings.serialNumber = e.target.value; }}
                />
                <MDBBtn className="btn-sm ml-0 mb-3" color="primary" onClick={this.activateLicense}>Aktivoi lisenssi</MDBBtn>

                {this.props.licenses.length === 0 ? <div className="text-default mb-2" style={{ fontSize: '10pt' }}>(Ei aktiivisia lisenssejä...)</div> : null}
                {this.props.licenses.map((value, index) => {
                    return <License key={index} license={value} />
                })}

                {/* <MDBBtn className="btn-sm ml-0 d-block" color="primary" onClick={ this.props.fetchLicenses }>Päivitä lisenssit</MDBBtn> */}

                <span>App ID</span><br />
                <span className="text-default" style={{ fontSize: '10pt', fontWeight: 'bold', wordBreak: 'break-all' }}>{this.props.settings.AppInstallationId}</span><br />
                {/* <MDBBtn className="btn-sm ml-0" color="default" onClick={ this.saveSettings }>Save settings</MDBBtn> */}
                Versio: <span className="text-default" style={{ fontSize: '10pt', fontWeight: 'bold' }}>{window.APP_VERSION}</span> <br />
                {/* <MDBBtn className="btn-sm ml-0" color="secondary" onClick={ this.update }>Empty cache</MDBBtn> */}
                <MDBBtn className="btn-sm ml-0" color="secondary" onClick={this.update}>Päivitä sovellus</MDBBtn>
                <br />
                <MDBBtn className="btn-sm ml-0" color="primary" onClick={this.fetchDecks} disabled={this.props.status.decksLoading ? true : false}>{this.props.status.decksLoading ? 'Haetaan ...' : 'Hae / päivitä pakat'}</MDBBtn>
                <span className="text-default" style={{ fontSize: '10pt', fontWeight: 'bold' }}>Haettu {decksFetched.getDate() + '.' + (decksFetched.getMonth() + 1) + '.' + decksFetched.getFullYear() + ' ' + decksFetched.getHours() + ':' + decksFetched.getMinutes()}</span>
                <MDBBtn className="btn-sm mr-0 float-right" color="danger" onClick={this.props.toggleModal}>Sulje</MDBBtn>

            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        settings: state.settings,
        licenses: state.licenses,
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        loadSettings,
        saveSettings,
        activateLicense,
        fetchDecks,
        fetchLicenses,
    }
)(Settings);
