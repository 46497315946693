import React from 'react';
import { connect } from 'react-redux';
import { loadSettings, saveSettings, loadLicenses, fetchDecks, loadDecks, fetchLatestVersionNumber } from '../actions';
import { MDBModal, MDBModalBody, MDBBtn } from "mdbreact";
import * as serviceWorker from '../serviceWorker';
import { clearCache } from "clear-cache"

class DataLoader extends React.Component {

    state = {};

    componentDidMount = () => {
        let duration = (Date.now() - this.props.settings.decksFetched) / 1000 / 60;
        if (duration && duration < 60) {
            this.checkVersion();
            return;
        }
        this.props.fetchLatestVersionNumber();
        this.props.loadSettings();
        this.props.loadLicenses();
        this.props.loadDecks();
        this.props.fetchDecks();
        this.checkVersion();
    }

    checkVersion = () => {
        if (this.props.settings.skipAppVersion === window.LATEST_VERSION) {
            return;
        }
        if (this.isNeverVersion(window.APP_VERSION, window.LATEST_VERSION)) {
            this.setState({ displayUpdatePrompt: true });
        }
    }

    // This kind of requires that version number are the same length e.g. x.xx.xx <--> x.xx.xx
    isNeverVersion = (vLocal, vRemote) => {
        let vNumLocal = parseInt(vLocal.replace(/\./g, ''));
        let vNumRemote = parseInt(vRemote.replace(/\./g, ''));
        // console.log(vNumLocal, vNumRemote);
        if (vNumRemote > vNumLocal) {
            return true;
        }
        return false;
    }

    update = async () => {
        // Don't update while data is being downloaded
        if (this.props.status.decksLoading) { return; }
        if (this.props.status.licensesLoading) { return; }
        serviceWorker.unregister();
        // window.location.reload(true);
        // window.location.href = `${window.location.href}`;
        clearCache();
    }

    skipUpdate = () => {
        this.setState({
            displayUpdatePrompt: false
        });
        this.props.saveSettings({ skipAppVersion: window.LATEST_VERSION });
    }

    toggleModal = () => {
        this.setState({
            displayUpdatePrompt: !this.state.displayUpdatePrompt
        });
    }

    render = () => {
        return (
            <MDBModal isOpen={this.state.displayUpdatePrompt} toggle={this.toggleModal} size="fluid" className="mt-2 mb-0">
                <MDBModalBody style={{ backgroundColor: '#212529' }}>
                    <div>
                        <h4>Uusi päivitys saatavilla
                            <i onClick={this.toggleModal} style={{ position: 'absolute', right: '1rem', top: '1rem' }} className="fas fa-times-circle text-danger"></i>
                        </h4>
                        Sovellukseen on saatavilla uusi versiopäivitys. ({window.LATEST_VERSION})
                    </div>
                    <MDBBtn className="btn-sm ml-0" color="primary" onClick={this.update}>{this.props.status.decksLoading ? 'Odota...' : 'Päivitä'}</MDBBtn>
                    <MDBBtn className="btn-sm mr-0 float-right" color="danger" onClick={this.skipUpdate}>Skippaa</MDBBtn>
                </MDBModalBody>
            </MDBModal>
        )
    }
}

function mapStateToProps(state) {
    return {
        settings: state.settings,
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        loadSettings,
        loadLicenses,
        fetchDecks,
        loadDecks,
        saveSettings,
        fetchLatestVersionNumber,
    }
)(DataLoader);
