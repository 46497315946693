import React from 'react';
import { MDBCard, MDBCardTitle } from "mdbreact";
import { browserName, osName, isIOS, isAndroid, isChrome, isFirefox } from 'react-device-detect';
// import config from '../../config/config.json';

class InstallInstructions extends React.Component {

    componentDidMount = () => {
        this.instructionsFound = false;
    }

    renderBrowserInfo = () => {
        return (
            <span className="ml-3" style={{ fontSize: '10pt' }}>
                {osName} ({browserName})
            </span>
        )
    }

    renderIOS = (force = false) => {
        if (!isIOS && !force) { return '' }
        this.instructionsFound = true;
        return (
            <div>
                <h5>iPhone (Safari)</h5>
                <p>
                    Paina keskellä alareunassa olevaa <img src="/img/icon_apple_share.svg" alt="" style={{ width: '24px' }} />-symbolia ja paina valikosta kohtaa <b><u>"Lisää Koti-valikkoon" (englanniksi "Add to home screen")</u></b>.
                    Tämän jälkeen sovellus löytyy puhelimesta muiden applikaatioiden joukosta.
                </p>
            </div>
        )
    }

    renderAndroidChrome = (force = false) => {
        if (!isAndroid && !force) { return '' }
        if (!isChrome && !force) { return '' }
        this.instructionsFound = true;
        return (
            <div>
                <h5>Android (Chrome)</h5>
                <p>
                    Paina oikeassa yläkulmassa olevaa  <i className="fas fa-ellipsis-v"></i> -symbolia ja paina valikosta kohtaa <b><u>"Asenna sovellus" (englanniksi "Install app")</u></b> tai <b><u>"Lisää aloitusnäyttöön" (englanniksi "Add to home screen")</u></b>.
                    Tämän jälkeen sovellus löytyy puhelimesta muiden applikaatioiden joukosta.
                </p>
            </div>
        )
    }

    renderAndroidSamsung = () => {
        if (!isAndroid) { return '' }
        if (browserName.indexOf('Samsung') === -1) { return '' }
        this.instructionsFound = true;
        return (
            <div>
                <h5>Android (Samsung)</h5>
                <p>
                    Paina osoiterivin oikealla puolella olevaa  <i class="fa fa-arrow-down"></i> -ikonia.
                    Tämän jälkeen sovellus löytyy puhelimesta muiden applikaatioiden joukosta.
                </p>
            </div>
        )
    }

    renderAndroidFirefox = () => {
        if (!isAndroid) { return '' }
        if (!isFirefox) { return '' }
        this.instructionsFound = true;
        return (
            <div>
                <h5>Android (Firefox)</h5>
                <p>
                    Paina osoiterivin oikealla puolella olevaa  <i class="fas fa-home"></i> -symbolia ja sen jälkeen <b><u>"Lisää Koti-valikkoon"</u></b>.
                    Tämän jälkeen sovellus löytyy puhelimesta muiden applikaatioiden joukosta.
                </p>
                <p>Huom! Kaikki sovelluksen ominaisuudet eivät välttämättä toimi Firefoxilla. Suosittelemme tämän sivun avaamista Chrome-selaimella.</p>
            </div>
        )
    }

    renderNoInstructions = () => {
        return (
            <div>
                {this.renderIOS(true)}
                {this.renderAndroidChrome(true)}
            </div>
        )
    }

    renderOldAppLink = () => {
        return (
            <div>
                <p>Jos haluat käyttää vanhaa Android-sovellusta, voit ladata sen <a href="/TURVAPPI_1.0.8-9_2017-05-08.apk">täältä</a>.</p>
            </div>
        )
    }

    render = () => {
        return (
            <div className="col-12 p-1">
                <MDBCard className="card-body w-100 default-color p-2">
                    <MDBCardTitle>Asennusohjeet {this.renderBrowserInfo()}</MDBCardTitle>
                    {this.renderIOS()}
                    {this.renderAndroidChrome()}
                    {this.renderAndroidSamsung()}
                    {this.renderAndroidFirefox()}
                    {!this.instructionsFound ? this.renderNoInstructions() : null}
                </MDBCard>
                v.{window.APP_VERSION}
            </div>
        )
    }
}

export default InstallInstructions;
