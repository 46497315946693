import React from 'react';
import { Link } from "react-router-dom";
import { MDBCard, MDBCardTitle } from "mdbreact";
import Flag from "./Flag";
import { withRouter } from 'react-router-dom';

class Deck extends React.Component {

    render = () => {
        const { appInstallationId } = this.props.match.params;
        return (
            <div className="col-6 p-1">
                <Link to={`/installed/${appInstallationId}/deck/${this.props.deck.Id}/`} style={{ color: '#ffffff' }}>
                    <MDBCard className="card-body w-100 p-2" style={{ backgroundColor: '#' + this.props.deck.Color, minHeight: '175px' }}>
                        <MDBCardTitle>{this.props.deck.Name}</MDBCardTitle>
                        <div style={{ fontSize: '10pt', position: 'absolute', right: '.5em', bottom: '.5em' }}>
                            <Flag lang={this.props.deck.Lang || ''}></Flag>
                        </div>
                    </MDBCard>
                </Link>
            </div>
        )
    }
}

export default withRouter(Deck);