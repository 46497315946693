import React from 'react';
import { connect } from 'react-redux';
import { removeLicense } from '../actions'

class License extends React.Component {

    delete = () => {
        if (window.confirm("Poistetaanko lisenssi? Menetät samalla kaikki lisenssiin liittyvät pakat.")) {
            this.props.removeLicense(this.props.license.licenseNumber);
        }
    }

    render = () => {
        return (
            <div className="p-2 special-color-dark mb-1 text-default position-relative" style={{fontSize: '10pt'}}>

                <i onClick={this.delete} style={{ position: 'absolute', right: '.5rem', top: '.5rem' }} className="fas fa-times-circle text-dark"></i>

                <div>
                    <span style={{fontWeight: 'bold'}}>Omistaja:</span> <span>{this.props.license.owner}</span>
                </div>
                <div>
                    <span style={{fontWeight: 'bold'}}>Voimassa:</span> <span>{this.props.license.expiresAt || 'toistaiseksi'}</span>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {};
}
export default connect(
    mapStateToProps,
    {
        removeLicense,
    }
)(License);
