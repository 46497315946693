import React from 'react';
import { connect } from 'react-redux';
import { MDBCard } from "mdbreact";
import ButtonBack from './ButtonBack';
import ButtonSearch from './ButtonSearch';
import { Swipeable } from 'react-swipeable';
import { Document, Page } from 'react-pdf';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

class CardFull extends React.Component {

    state = {};

    previousCard = () => {
        let cId = this.cardId - 1;
        let categoryId = this.categoryId;
        if (cId < 0) {
            // Move to previous category
            if (categoryId <= 0) {
                return;
            }
            categoryId -= 1;
            cId = -1;
        }
        this.setState({ moveRight: true });
        setTimeout(() => {
            this.setState({ moveRight: false });
            const { appInstallationId } = this.props.match.params;
            this.props.history.push(`/installed/${appInstallationId}/deck/${this.deckId}/category/${categoryId}/card/${cId}/`);
        }, 300);
    }

    nextCard = () => {
        let cId = this.cardId + 1;
        let categoryId = this.categoryId;
        if (cId >= this.cardQuantity) {
            // Move to next category
            if (categoryId >= this.categoryQuantity - 1) {
                return;
            }
            categoryId += 1;
            cId = 0;
        }
        this.setState({ moveLeft: true });
        setTimeout(() => {
            this.setState({ moveLeft: false });
            const { appInstallationId } = this.props.match.params;
            this.props.history.push(`/installed/${appInstallationId}/deck/${this.deckId}/category/${categoryId}/card/${cId}/`);
        }, 300);
    }

    render = () => {

        let { deckId, categoryId, cardId } = this.props.match.params;
        deckId = parseInt(deckId);
        categoryId = parseInt(categoryId);
        cardId = parseInt(cardId);

        let deck;
        let category;
        let cards = [];
        let card;

        this.categoryQuantity = 0;
        if (Array.isArray(this.props.decks)) {
            deck = this.props.decks.filter(obj => {
                return obj.Id === parseInt(deckId);
            })[0];

            if (deck) {
                if (Array.isArray(deck.Categories)) {
                    category = deck.Categories[categoryId];
                    this.categoryQuantity = deck.Categories.length;
                    // Card with index -1 means the last card
                    if (cardId === -1) {
                        cardId = category.Cards.length - 1;
                    }
                    if (category) {
                        cards = category.Cards;
                        card = cards[cardId];
                        this.card = card;
                    }
                }
            }
        }

        this.deckId = deckId;
        this.categoryId = categoryId;
        this.cardId = cardId;

        this.cardQuantity = cards.length;

        // Get contents of card's first div
        let divContents = '';
        if (card) {
            if (card.ContentType === 'pdf') {
                // Display pdf
                divContents = `<div id="card-inner" class="card mt-4 py-3 animated fadeIn faster"><h2 class="category">${card.Name}</h2>[display pdf]</div>`;
            }
            else {
                // Default: Html content
                divContents = card.HtmlContent;
                divContents = divContents.substring(divContents.indexOf('<div'));
                divContents = divContents.replace('</body>', '').replace('</html>', '');
                divContents = divContents.replace('<div class="card', '<div id="card-inner" style="margin-top: 2.25rem" class="card py-3 animated fadeIn faster');
                divContents = divContents.replace('<a href=', '<a target="_blank" href=');
            }

        }

        let iframeHeight = window.innerHeight - 90;

        return (

            <div className="container p-0" id="card-full">

                <h4 className="mb-0" style={{ textAlign: 'center' }}>Kortti <span className="grey-text ml-2" style={{ fontSize: '12pt' }}>{(this.cardId + 1)}/{this.cardQuantity}</span></h4>

                <Swipeable onSwipedLeft={this.nextCard} onSwipedRight={this.previousCard} className="h-100">

                    <MDBCard className="card-body w-100 p-0 mt-1" style={{ backgroundColor: '#' + (card ? card.Color : '000000'), minHeight: '100%' }}>
                        <div className={(this.state.moveLeft ? 'animated fadeOutLeft faster' : null) + ' ' + (this.state.moveRight ? 'animated fadeOutRight faster' : null)}>
                            <div id="card-contents" className="text-dark px-4 pt-4 pb-4" style={{ height: iframeHeight, overflow: 'scroll', backgroundColor: '#' + (card ? card.Color : '000000') }} >
                                {(card && !card.ContentType) ? <div dangerouslySetInnerHTML={{ __html: divContents }} /> : null}
                                {(card && card.ContentType) === 'pdf' ?
                                    <h2 className="" style={{ color: '#fff' }}>{category.Name}</h2>
                                    : null}
                                {(card && card.ContentType) === 'pdf' ?
                                    <Document file={"data:application/pdf;base64," + card.PdfContent}>
                                        <Page pageNumber={1} style={{ width: '100%', height: 'unset' }} />
                                    </Document> : null}
                                {(card && card.ContentType === 'pdf') ? <div className="p-2">&nbsp;</div> : null}
                                {(card && !card.ContentType) ? <div className="p-1">&nbsp;</div> : null}
                            </div>-
                        </div>
                    </MDBCard>

                    {this.cardId > 0 || this.categoryId > 0 ?
                        <div className="btn-sm m-0 p-2" onClick={this.previousCard} style={{ position: 'fixed', top: '45%', left: '0', height: '100px', width: '24px', lineHeight: '85px', backgroundColor: 'rgba(33,33,33,0.25)', textAlign: 'center' }}>
                            <FontAwesomeIcon className="text-light" icon={faChevronLeft} size="lg" />
                        </div>
                        :
                        null
                    }

                    {this.cardId < this.cardQuantity - 1 || this.categoryId < this.categoryQuantity - 1 ?
                        <div className="btn-sm m-0 p-2" onClick={this.nextCard} style={{ position: 'fixed', top: '45%', right: '0', height: '100px', width: '24px', lineHeight: '85px', backgroundColor: 'rgba(33,33,33,0.25)', textAlign: 'center' }}>
                            <FontAwesomeIcon className="text-light" icon={faChevronRight} size="lg" />
                        </div>
                        :
                        null
                    }

                </Swipeable>

                <ButtonBack steps={(deck?.DisplayList === true || cards?.length === 1) ? 4 : 2} />
                <ButtonSearch stepsBack='4' />

            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        decks: state.decks
    };
}
export default connect(
    mapStateToProps,
    {
        foo: "bar"
    }
)(CardFull);
