import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { MDBCard, MDBCardTitle } from "mdbreact";

class Card extends React.Component {

    render = () => {
        const { appInstallationId, deckId, categoryId } = this.props.match.params;
        return (
            <div className="col-6 p-1">
                <Link to={`/installed/${appInstallationId}/deck/${deckId}/category/${categoryId}/card/${this.props.cardId}/`} style={{color: '#ffffff'}}>
                    <MDBCard className="card-body w-100 p-2" style={{backgroundColor: '#'+this.props.card.Color, minHeight: '175px'}}>
                        <MDBCardTitle>{this.props.card.Name}</MDBCardTitle>
                    </MDBCard>
                </Link>
            </div>
        )
    }
}

export default withRouter(Card);
