import React from 'react';
import Flags from 'country-flag-icons/react/3x2'

class Flag extends React.Component {

    // Map language codes to flag components
    getFlag = (lang) => {
        switch(lang) {
            case 'en':
                return <Flags.GB />
            case 'lv':
                return <Flags.LV />
            case 'lt':
                return <Flags.LT />
            case 'pl':
                return <Flags.PL />
            case 'sv':
                return <Flags.SE />
            case 'fi':
                return <Flags.FI />
            case 'ur':
                return <Flags.UA />
            case 'ru':
                return <Flags.RU />
            case 'et':
                return <Flags.EE />
            default:
              return null;
        }
    }

    render = () => {
        if (!this.props.lang) {
            return null;
        }
        return (
            <div style={{width:'40px', height: '27px'}}>
                {this.getFlag(this.props.lang)}
            </div>
        )
    }
}

export default (Flag)
