import { v4 as uuidv4 } from 'uuid';
import api from '../api';
import {
    loadSetting as idbLoadSetting,
    loadSettings as idbLoadSettings,
    saveSettings as idbSaveSettings,
    saveLicense,
    saveLicenses,
    loadLicenses as idbLoadLicenses,
    removeLicense as idbRemoveLicense,
    saveDecks,
    loadDecks as idbLoadDecks
} from '../idb';

export const loadSettings = () => {
    return async (dispatch) => {
        const response = await idbLoadSettings();
        const url = window.location.hash.split('/');
        let appInstallationId = null
        if (url.length > 2 && url[2] !== '0') {
            appInstallationId = url[2]
        }

        if (!response.AppInstallationId) {
            // No AppInstallationId means browser data is empty: Fetch from url and save
            if (!appInstallationId) {
                // No AppId in url: old installation, generate uuid
                // TODO: Maybe prompt user to reinstall app?
                response.AppInstallationId = uuidv4();
            } else {
                response.AppInstallationId = url[2];
            }
            await idbSaveSettings(response);
            // TODO: Fetch serial codes from the server (when implemented)
        }
        dispatch({ type: 'UPDATE_SETTINGS', payload: response });
    }
}

export const fetchLatestVersionNumber = () => {
    return async (_dispatch) => {
        const response = await api.post('/api/mobile/latestversion');
        window.LATEST_VERSION = response.data.latestVersion;
    }
}

export const loadLicenses = () => {
    return async (dispatch) => {
        const response = await idbLoadLicenses();
        dispatch({ type: 'UPDATE_LICENSES', payload: response });
    }
}

export const saveSettings = (data) => {
    return async (dispatch) => {
        await idbSaveSettings(data);
        dispatch({ type: 'UPDATE_SETTINGS', payload: data });
    }
}

export const activateLicense = (appInstallationId, serialNumber) => {
    return async function (dispatch, getState) {
        console.log("Activating license...");
        const response = await api.post('/api/mobile/activate', { AppInstallationId: appInstallationId, Code: serialNumber });
        if (response.status === 200) {
            await saveLicense(response.data);
            const licenses = await idbLoadLicenses();
            dispatch({ type: 'UPDATE_LICENSES', payload: licenses });
            dispatch(fetchDecks(true));
        }
        else {
            alert("Lisenssin aktivointi epäonnistui: " + response.data);
        }
    }
}

export const fetchLicenses = () => {
    return async function (dispatch, getState) {

        dispatch({ type: 'SET_STATUS', payload: { licensesLoading: true } });
        const licenses = await idbLoadLicenses();
        let accessTokens = [];
        for (let i in licenses) {
            accessTokens.push(licenses[i].token);
        }
        const response = await api.post('/api/mobile/refreshLicenses', accessTokens);
        if (response.status === 200) {
            let newLicenses = response.data;
            // Licenses from 'refreshLicenses' API call do not have access tokens, use the existing ones
            let oldLicenses = await idbLoadLicenses();
            for (let iOld in oldLicenses) {
                for (let iNew in newLicenses) {
                    if (oldLicenses[iOld].licenseNumber === newLicenses[iNew].licenseNumber) {
                        newLicenses[iNew].token = oldLicenses[iOld].token;
                    }
                }
            }
            await saveLicenses(newLicenses);
            dispatch({ type: 'SET_STATUS', payload: { licensesLoading: false } });
            dispatch({ type: 'UPDATE_LICENSES', payload: newLicenses });
            idbSaveSettings({ licensesFetched: Date.now() });
        }
        else {
            dispatch({ type: 'SET_STATUS', payload: { licensesLoading: false } });
            alert("Lisenssien haku epäonnistui: " + response.data);
        }
    }
}

export const fetchDecks = (force) => {
    return async function (dispatch, getState) {

        let lastUpdate = await idbLoadSetting('decksFetched');
        let duration = (Date.now() - lastUpdate) / 1000 / 60;  // This many minutes ago
        if (duration < 60 && !force) {
            return;
        }

        dispatch({ type: 'SET_STATUS', payload: { decksLoading: true } });

        // Delay for testing purposes
        // await new Promise(resolve => setTimeout(resolve, 10000));

        const licenses = await idbLoadLicenses();
        if (licenses.length === 0) {
            dispatch({ type: 'SET_STATUS', payload: { decksLoading: false } });
            return;
        }
        let accessTokens = [];
        for (let i in licenses) {
            accessTokens.push(licenses[i].token);
        }

        const response = await api.post('/api/mobile/refresh', accessTokens);
        if (response.status === 200) {
            let json;
            if (typeof response.data === 'string') {
                json = JSON.parse(response.data);
            }
            else {
                json = response.data;
            }
            await saveDecks(json);
            let newDecks = await idbLoadDecks();
            dispatch({ type: 'SET_STATUS', payload: { decksLoading: false } });
            dispatch({ type: 'UPDATE_DECKS', payload: newDecks });
            idbSaveSettings({ decksFetched: Date.now() });
            dispatch(loadSettings());
        }
        else {
            dispatch({ type: 'SET_STATUS', payload: { decksLoading: false } });
            alert("Pakkojen haku epäonnistui: " + response.data);
        }
    }
}

export const loadDecks = (accessTokens) => {
    return async function (dispatch, getState) {
        let decks = await idbLoadDecks();
        dispatch({ type: 'UPDATE_DECKS', payload: decks });
    }
}

export const removeLicense = (licenseNumber) => {
    return async function (dispatch, getState) {
        await idbRemoveLicense(licenseNumber);
        const licenses = await idbLoadLicenses();
        dispatch({ type: 'UPDATE_LICENSES', payload: licenses });
    }
}

export const search = (searchTerm) => {
    return ({ type: 'SEARCH', payload: { searchTerm: searchTerm } });
}

export const openSearchModal = () => {
    return ({ type: 'OPEN_SEARCH_MODAL' });
}

export const closeSearchModal = () => {
    return ({ type: 'CLOSE_SEARCH_MODAL' });
}