import Dexie from 'dexie';

const dbName = 'turvappi';
const dbVersion = 1;

const db = new Dexie(dbName);
db.version(dbVersion).stores(
    {
        settings: '&key, value',
        licenses: '&licenseNumber, owner, token, expiresAt, validFrom, frozen, decks',
        decks: '&Id, PublishedAt, Name, Categories, Color, UseCategoryList'
    }
);

const loadSettings = async () => {
    const response = await db.settings.toArray();
    var d = {};
    for(let i in response) {
        d[response[i].key] = response[i].value;
    }
    return d;
}

const loadSetting = async (key) => {
    const response = await db.settings.get({key: key});
    if (response) {
        return response.value;
    }
    return response;
}

const deleteSetting = async (id) => await db.settings.delete(id);

const saveSetting = async (setting) => {
    return await db.settings.put(setting);
};

const saveSettings = async (settings) => {
    var d = [];
    for(let i in settings) {
        d.push({key: i, value: settings[i]});
    }
    return await db.settings.bulkPut(d);
};

const saveLicense = async (license) => {
    return await db.licenses.put(license);
}

const saveLicenses = async (licenses) => {
    for (let i in licenses) {
        saveLicense(licenses[i]);
    }
}

const loadLicenses = async () => {
    return await db.licenses.toArray();
}

const removeLicense = async (licenseNumber) => {
    const response = await db.licenses.delete(licenseNumber);
}

const saveDecks = async (decks) => {
    // Remove rendundant decks
    const oldDecks = await loadDecks();
    var deckFound;
    for (let i in oldDecks) {
        if (decks.filter( e => e.Id === oldDecks[i].Id ).length === 0) {
            await removeDeck(oldDecks[i].Id);
        }
    }

    await db.decks.bulkPut(decks);
}

const removeDeck = async (id) => {
    const response = await db.decks.delete(id);
}

const loadDecks = async () => {
    return await db.decks.toArray();
}

export {
    loadSettings,
    loadSetting,
    deleteSetting,
    saveSetting,
    saveSettings,
    saveLicense,
    saveLicenses,
    loadLicenses,
    removeLicense,
    saveDecks,
    loadDecks,
};