import React from 'react';
import { connect } from 'react-redux';
import Category from './Category';
import ButtonBack from './ButtonBack';
import ButtonSearch from './ButtonSearch';
import { MDBCard, MDBCardText } from "mdbreact";
import { Link, Redirect } from "react-router-dom";

class Categories extends React.Component {

    render = () => {
        let { deckId } = this.props.match.params;
        let deck;
        if (Array.isArray(this.props.decks)) {
            deck = this.props.decks.filter(obj => {
                return obj.Id === parseInt(deckId);
            })[0];
        }
        let categories = deck ? deck.Categories : [];

        let { appInstallationId } = this.props.match.params;

        // Skip this view if "Use category list" is unchecked
        if (deck?.UseCategoryList === false) {
            return <Redirect to={`category/0/`} />
        }

        if (deck && deck.DisplayList) {
            // List mode
            return (
                <div className="mt-1 pb-5">
                    <h4 className="mb-0" style={{ textAlign: 'center' }}>Kortit</h4>
                    <ButtonBack />
                    <ButtonSearch />
                    {
                        categories.map((category, iCategory) => {
                            return (
                                <div key={iCategory}>
                                    <MDBCard className="card-body px-2 py-1 mb-1" style={{ backgroundColor: '#404040' }}>
                                        <MDBCardText className="mb-0">
                                            {category.Name}
                                        </MDBCardText>
                                    </MDBCard>
                                    {category.Cards.map((card, iCard) => {
                                        return (
                                            <Link key={iCard} to={"/installed/" + appInstallationId + "/deck/" + deckId + "/category/" + iCategory + "/card/" + iCard + "/"} style={{ color: '#ffffff' }} onClick={this.close}>
                                                <MDBCard className="card-body ml-4 mb-1 px-2 py-1" style={{ backgroundColor: '#' + card.Color }}>
                                                    <MDBCardText>
                                                        {card.Name}
                                                    </MDBCardText>
                                                </MDBCard>
                                            </Link>
                                        )
                                    })}
                                </div>
                            )
                        })
                    }
                </div>
            );
        }
        else {
            // Default display mode: tiles
            return (
                <div className="container mt-1">
                    <h4 className="mb-0" style={{ textAlign: 'center' }}>Kategoriat</h4>

                    <ButtonBack />
                    <ButtonSearch />

                    <div className="row">
                        {
                            categories.map((val, i) => {
                                return <Category key={i} categoryId={i} category={val} />
                            })
                        }
                    </div>
                    <div className="p-2">&nbsp;</div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        decks: state.decks,
    };
}
export default connect(
    mapStateToProps,
    {}
)(Categories);
