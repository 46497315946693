import React from 'react';
import { connect } from 'react-redux';
import Card from './Card';
import ButtonBack from './ButtonBack';
import ButtonSearch from './ButtonSearch';
import { Redirect } from "react-router-dom";

class Cards extends React.Component {

    render = () => {
        var { deckId, categoryId } = this.props.match.params;
        var deck;
        var category;
        var cards = [];
        if (Array.isArray(this.props.decks)) {
            deck = this.props.decks.filter(obj => {
                return obj.Id === parseInt(deckId);
            })[0];

            if (deck) {
                if (Array.isArray(deck.Categories)) {
                    category = deck.Categories[categoryId];
                    if(category) {
                        cards = category.Cards;
                    }
                }
            }
        }

        // Skip this view if only one card
        if (cards?.length === 1) {
            return <Redirect to={`card/0/`} />
        }

        return (
            <div className="container mt-1">
                <h4 className="mb-0" style={{textAlign: 'center'}}>Kortit</h4>
                <ButtonBack steps={deck?.UseCategoryList === false ? 4 : 2} />
                <ButtonSearch stepsBack='2' />
                <div className="row">
                    {
                        cards.map( (val, i) => {
                            return <Card key={i} cardId={i} card={val} />
                        })
                    }
                </div>
                <div className="p-2">&nbsp;</div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        decks: state.decks
    };
}
export default connect(
    mapStateToProps,
    {
        foo: "bar"
    }
)(Cards);
