import React from 'react';
import './css/style.css';

import { connect } from 'react-redux';
// import { MDBCard, MDBCardTitle, MDBCardText } from "mdbreact";
// import { MDBBtn } from "mdbreact";
import { loadSettings, loadLicenses, fetchDecks, loadDecks } from './actions'
import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

// Components
import ToolBar from "./components/ToolBar";
import BottomBar from "./components/BottomBar";
import Decks from "./components/Decks";
import Search from "./components/Search";
import Categories from "./components/Categories";
import Cards from "./components/Cards";
import CardFull from "./components/CardFull";
import InstallInstructions from "./components/InstallInstructions";
import DataLoader from "./components/DataLoader";

class App extends React.Component {

    componentDidMount = () => {
        if (window.CSS_FILE) {
            this.loadAdditionalCss(window.CSS_FILE);
        }
    }

    fetchDecks = () => {
        this.props.fetchDecks();
    }

    loadAdditionalCss = (fileName) => {
        import(`./css/${fileName}`).then(r => {
        });
    };

    render = () => {

        return (
            <div className="h-100">
                <Router>

                    <Switch>

                        <Route path="/installed">
                            <ToolBar displaySettings={true} />
                            <DataLoader/>
                            <Route exact path="/installed" ><Redirect to="/installed/0/" /></Route>

                            <Route exact path="/installed/:appInstallationId" component={Decks} />
                            <Route exact path="/installed/:appInstallationId/deck/:deckId" component={Categories} />
                            <Route exact path="/installed/:appInstallationId/deck/:deckId/category/:categoryId" component={Cards} />
                            <Route exact path="/installed/:appInstallationId/deck/:deckId/category/:categoryId/card/:cardId" component={CardFull} />
                            <Route path="/installed/:appInstallationId/deck/:deckId" component={Search} />
                            <BottomBar />
                        </Route>
                        <Route path="/">
                            <ToolBar displaySettings={false} />
                            <InstallInstructions />
                        </Route>
                    </Switch>
                </Router>
            </div>
        );
    }
}

// export default App;
function mapStateToProps(state) {
    return {
        settings: state.settings,
        licenses: state.licenses,
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        loadSettings,
        loadLicenses,
        fetchDecks,
        loadDecks,
    }
)(App);
