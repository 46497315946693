import React from 'react';
import { connect } from 'react-redux';
import { MDBCard, MDBInput, MDBCardText, MDBBtn } from "mdbreact";
import { MDBModal, MDBModalBody } from "mdbreact";
import { Link } from "react-router-dom";
import { search, closeSearchModal } from '../actions'

class Search extends React.Component {

    state = { searchTerm: '' }

    close = () => {
        this.props.closeSearchModal();
    }

    search = () => {
        this.props.searchAction(this.state.searchTerm);
    }

    searchTermChanged = (e) => {
        this.setState({ searchTerm: e.target.value })
    }

    render = () => {
        let { deckId } = this.props.match.params;
        let deck;
        if (Array.isArray(this.props.decks)) {
            deck = this.props.decks.filter(obj => {
                return obj.Id === parseInt(deckId);
            })[0];
        }
        let categories = deck ? deck.Categories : [];

        const { appInstallationId } = this.props.match.params;

        return (
            <MDBModal isOpen={this.props.status.searchModalOpen} toggle={this.close} size="fluid" className="mt-2 mb-0">
                <MDBModalBody className="p-0" style={{ backgroundColor: '#212529' }}>

                    <div className="container mt-1 p-1">
                        <h4 className="mb-0" style={{ textAlign: 'center' }}>
                            Haku
                            {this.props.search.searchTerm ?
                                <span className="ml-2" style={{ fontSize: '12pt' }}>"{this.props.search.searchTerm}"</span>
                                :
                                null
                            }
                            <i onClick={this.close} style={{ position: 'absolute', right: '10px', top: '10px' }} className="fas fa-times-circle text-danger"></i>
                        </h4>

                        <MDBInput
                            label="Hakusana"
                            icon="search"
                            type="text"
                            className="text-light"
                            defaultValue={this.props.search.searchTerm || this.state.search || ''}
                            onChange={(e) => { this.searchTermChanged(e); }}
                        />

                        <div className="text-center">
                            <MDBBtn className="btn-primary w-75 mb-3" onClick={this.search}>Etsi</MDBBtn>
                        </div>

                        {
                            categories.map((category, iCategory) => {
                                return (
                                    <div key={iCategory}>
                                        {/* <MDBCard className="card-body px-2 py-1 mb-1" style={{backgroundColor: '#'+category.Color}}> */}
                                        <MDBCard className="card-body px-2 py-1 mb-1" style={{ backgroundColor: '#404040' }}>
                                            <MDBCardText className="mb-0">
                                                {/* <h5 className="mb-0">{category.Name}</h5> */}
                                                {category.Name}
                                            </MDBCardText>
                                        </MDBCard>
                                        {category.Cards.map((card, iCard) => {
                                            return (
                                                (card.TextContent && (card.TextContent || '').toLowerCase().includes((this.props.search.searchTerm || '').toLowerCase() || ''))
                                                    || (card.Name && card.Name.toLowerCase().includes((this.props.search.searchTerm || '').toLowerCase()))
                                                    ?
                                                    <Link key={iCard} to={`/installed/${appInstallationId}/deck/${deckId}/category/${iCategory}/card/${iCard}/`} style={{ color: '#ffffff' }} onClick={this.close}>
                                                        <MDBCard className="card-body ml-4 mb-1 px-2 py-1" style={{ backgroundColor: '#' + card.Color }}>
                                                            <MDBCardText>
                                                                {card.Name}
                                                            </MDBCardText>
                                                        </MDBCard>
                                                    </Link>
                                                    :
                                                    null
                                            )
                                        })}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <MDBBtn className="btn-sm float-right" color="danger" onClick={this.close}>Sulje</MDBBtn>
                </MDBModalBody>
            </MDBModal>
        )
    }
}

function mapStateToProps(state) {
    return {
        decks: state.decks,
        search: state.search,
        status: state.status,
    };
}
export default connect(
    mapStateToProps,
    {
        searchAction: search,
        closeSearchModal
    }
)(Search);
