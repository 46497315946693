import React from 'react';
import { Link } from "react-router-dom";
import { MDBBtn } from "mdbreact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

class ButtonBack extends React.Component {

    render = () => {
        const steps = parseInt(this.props.steps) || 2;
        let linkto = '';
        for(let i = 0; i < steps; i++) {
            linkto += '../';
        }
        return (
            <Link to={linkto} style={{color: '#ffffff', position: 'absolute', left: 0, top: '57px'}}>
                <MDBBtn className="btn-sm m-0 p-2" color="dark" onClick={ this.saveSettings }>
                    <FontAwesomeIcon className="grey-text" icon={faArrowLeft} size="lg"/>
                    <span className="grey-text ml-2">Takaisin</span>
                </MDBBtn>
            </Link>
        )
    }
}

export default ButtonBack;
