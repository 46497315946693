import React from 'react';
import { connect } from 'react-redux';

class Spinner extends React.Component {

    render = () => {

        return (
            <div className="spinner-border" style={{color: '#808080', width:'1.25em', height: '1.25em'}} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        );

    }
}

// function mapStateToProps(state) {
//     return {
//         status: state.status
//     };
// }
// export default connect(
//     mapStateToProps,
//     {
//         "foo": "bar"
//     }
// )(Spinner);

export default Spinner;