import React from 'react';
import { connect } from 'react-redux';
import { MDBModal, MDBModalBody } from "mdbreact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import Settings from "./Settings";
import Spinner from "./Spinner";
// import icon from '../img/icon.png';

class ToolBar extends React.Component {

    state = {
        modal: false,
        icon: {},
    }

    componentDidMount = () => {
        // Load icon dynamically based on settings
        this.loadIcon(window.ICON);
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    loadIcon = (imageName) => {
        try {
            import(`../img/${imageName}`).then(icon => {
                this.setState({
                    icon
                });
            });
        }
        catch(e) {

        }
    };

    render = () => {
        return (
            <div>
                <div id="toolbar" className="footer p-2 text-dark">
                    <img id="logo-icon" src={this.state.icon.default} alt="" />
                    <span className="text-light ml-2" style={{ fontSize: '20pt' }}>{window.APP_NAME}</span>

                    {this.props.displaySettings ? <FontAwesomeIcon className="float-right mt-2 mr-1 grey-text" icon={faCog} size="lg" onClick={this.toggleModal} /> : null}

                    <div className="d-inline ml-4 mt-2 mr-2">
                        {(this.props.status.decksLoading || this.props.status.licensesLoading) ? <Spinner /> : null}
                    </div>

                </div>

                <MDBModal isOpen={this.state.modal} toggle={this.toggleModal} size="fluid" className="mt-2 mb-0">
                    <MDBModalBody style={{ backgroundColor: '#212529' }}>
                        <Settings toggleModal={this.toggleModal} />
                    </MDBModalBody>
                </MDBModal>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        "foo": "bar"
    }
)(ToolBar);

// export default ToolBar;