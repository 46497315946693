import axios from 'axios';

// let baseURL = 'https://x-pakka.trineria.fi/';
let baseURL = '/';
if (window.location.host.includes("localhost")) {
    baseURL = 'http://localhost:9002/';
}

export default axios.create({
    baseURL: baseURL,
    validateStatus: () => true,
});