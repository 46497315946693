import { combineReducers } from 'redux';

function settingsReducer(state = {}, action) {
    if (action.type === 'UPDATE_SETTINGS') {
        return action.payload;
    }
    return state;
}

function licenseReducer(state=[], action) {
    if (action.type === 'ADD_LICENSE') {
        return [...state, action.payload];
    }
    if (action.type === 'REMOVE_LICENSE') {
        console.log("TODO: Remove license");
    }
    if (action.type === 'UPDATE_LICENSES') {
        return action.payload;
    }
    return state;
}

function decksReducer(state = {}, action) {
    if (action.type === 'UPDATE_DECKS') {
        return action.payload;
    }
    return state;
}

function searchReducer(state = {}, action) {
    if (action.type === 'SEARCH') {
        return action.payload;
    }
    return state;
}

function statusReducer(state={}, action) {
    if (action.type === 'SET_STATUS') {
        return {...state, ...action.payload};
    }

    if (action.type === 'OPEN_SEARCH_MODAL') {
        return {...state, ...{searchModalOpen: true}}
    }

    if (action.type === 'CLOSE_SEARCH_MODAL') {
        return {...state, ...{searchModalOpen: false}}
    }

    return state;
}

export default combineReducers({
    settings: settingsReducer,
    licenses: licenseReducer,
    decks: decksReducer,
    search: searchReducer,
    status: statusReducer,
});